import React from 'react';
import { css } from 'linaria';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Main from '../components/main';
import Locations from '../components/locations';
import { Action } from 'mediasoil-shared-components/components/';
import cls from 'mediasoil-shared-components/utils/class_strings';
import AboutImage from 'Img/about.jpg';

const styles = {
  img: css`
    float: left;
    margin: 0 helpers.$gutter helpers.$gutter 0;
    max-width: 100%;
    width: 25em;
  `,
  h2: css`
    clear: both;
  `,
};

export default () => (
  <Layout>
    <SEO title="About C-N-C Fireworks" />
    <Main>
      <div>
        <h1 className={styles.h1}>About C-N-C Fireworks</h1>
      </div>
      <img alt="C-N-C Fireworks Tent" className={styles.img} loading="lazy" src={AboutImage} />
      <p>
        C-N-C Fireworks is the only full time locally owned firework business located in Beatrice, Nebraska. Chris
        Cullison, founder and operator of C-N-C Fireworks, started in the firework industry in 2001. In 2008, after
        getting licensed by the PGI (Pyrotechnics Guild International) and APA (American Pyro Association), C-N-C
        Fireworks was founded. Since then, this company has become a staple in the community. We make sure that we
        choose the highest grade of fireworks in order to bring you quality that outshines our competition.
      </p>
      <p>
        As a member of the Beatrice Chamber of Commerce, we support our community in any way that we can. We have shot
        the Homestead Days fireworks show for the last several years and been utilized by local business owners to shoot
        customer appreciation shows. The donation of the use of our sound equipment has allowed a larger budget for the
        Homestead Days show.
      </p>
      {/* <h2 className={cls(styles.h2, 'h3')}>Three Locations</h2>
      <p>
        Our popular Ace Hardware parking lot location will house the iconic Hot Shot green dragon and will offer
        curbside pick up as well as free home delivery for Beatrice city limits.
      </p>
      <p>
        This year we are proud to partner with two amazing organizations serving the veteran community in Lincoln, NE.
        The first one is VFW Post #3606. The second is a new post of the American Legion, #1919. All three locations are
        able to provide curbside pickup with an online order.
      </p>
      <p>
        We appreciate the opportunity to work with these two non-profit organizations and hope you will consider giving
        them your business. The proceeds at these locations will be used to support veterans. To find out more about
        these organizations, please visit their websites.
      </p> */}
      <h2 className={cls(styles.h2, 'h3')}>Firework Brands</h2>
      <p>
        C-N-C Fireworks carries all your favorite brands as well as C-N-C Fireworks exclusive brand Hot Shot. All of our
        products are backed by our "100% No Dud Guarantee!"&dagger;.
      </p>
      <h2 className={cls(styles.h2, 'h3')}>Professional Shoots</h2>
      <p>
        Although most of our business occurs during fireworks season, our professional pyrotechnicians are able to
        provide professional shows year round. We also have a variety of products that can be purchased throughout the
        year for events such as gender reveals, graduations, weddings, anniversaries, and more.{' '}
        <Action href="/contact">Contact Us</Action> to add a little extra sparkle and pop to your next big event!
      </p>
      <p>
        <small>&dagger; defective products must be returned for a replacement</small>
      </p>
      <h2 className={cls(styles.h2, 'h3')}>Locations</h2>
      <Locations />
    </Main>
  </Layout>
);
