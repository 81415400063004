import React from 'react';
import { css } from 'linaria';
import { parse, format } from 'date-fns';
import { Action } from 'mediasoil-shared-components/components/';
import { locations } from '../../../content/productData.json';

const styles = {
  locations: css`
    display: flex;
    list-style: none;
    margin: -1em 0 0;
    padding: 0;

    @include helpers.mq(locations, true) {
      flex-direction: column;
      margin: -2em 0 0;
    }

    li {
      box-shadow: 0 0 1px 1px #ccc inset;
      flex: 1;
      margin: 0 helpers.$gutter / 2;
      padding: helpers.$gutter;

      @include helpers.mq(locations, true) {
        margin-top: helpers.$gutter;
      }

      h3 {
        font-size: 1.25em;
        margin-top: 0;
      }
    }
  `,
  hours: css`
    font-size: 0.9em;
    margin-top: helpers.$gutter/2;
    text-transform: lowercase;

    b {
      text-transform: none;
    }
  `,
};

export const activeLocations = locations.filter(({ status }) => status === 'ACTIVE');

export const processHours = (periods = []) => {
  const currentYear = new Date().getFullYear();
  const offsetDate = `07-04-${currentYear}`;
  const startDayOfWeek = format(new Date(offsetDate), 'iii').toUpperCase();
  const sortedDaysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
  const numDays = periods.length;

  sortedDaysOfWeek.find((day, _, arr) => {
    arr.push(arr.shift());

    if (day === startDayOfWeek) {
      return true;
    }
  });

  // Shift one more as we want to end with July 4th
  sortedDaysOfWeek.push(sortedDaysOfWeek.shift());

  return periods
    .sort(({ day_of_week: a }, { day_of_week: b }) => sortedDaysOfWeek.indexOf(a) - sortedDaysOfWeek.indexOf(b))
    .map(({ start_local_time, end_local_time, day_of_week }, i) => {
      const date = new Date(offsetDate);

      // Set day offset
      date.setDate(date.getDate() - numDays + i + 1);

      const parsedLocalStartTime = parse(start_local_time, 'HH:mm:ss', date);
      const parsedLocalEndTime = parse(end_local_time, 'HH:mm:ss', date);

      return [
        format(parsedLocalStartTime, 'MMMM do'),
        format(parsedLocalStartTime, 'h:mm a'),
        format(parsedLocalEndTime, 'h:mm a'),
      ];
    });
};

export default () => (
  <ol className={styles.locations}>
    {activeLocations.map(({ id, name, address, phone_number, business_email, business_hours }) => {
      return (
        <li key={id}>
          <h3>{name}</h3>
          <address>
            {address.address_line_1}
            <br />
            {address.address_line_2 ? (
              <>
                {address.address_line_2}
                <br />
              </>
            ) : null}
            {address.locality}, {address.administrative_district_level_1} {address.postal_code}
            <br />
            <br />
            Phone: <Action href={`tel:${phone_number}`}>{phone_number}</Action>
            <br />
            Email: <Action href={`mailto:${business_email}`}>{business_email}</Action>
          </address>
          <br />
          <strong>Hours:</strong>

          {processHours(business_hours?.periods).map(([day, start, end], i) => {
            return (
              <div key={i} className={styles.hours}>
                <b>{day}</b> &mdash; {start} - {end}
              </div>
            );
          })}
        </li>
      );
    })}
  </ol>
);
